//import $ from 'jquery';
//import 'bootstrap';
//import 'bootstrap-select';

/*
import $ from 'jquery';
window.jQuery = $;
window.$ = $;*/

/*
$(document).ready(function () {

    // selectpicker
    $('select').selectpicker();

});
*/

// Change navbar BG color when scrolling over sections
/*
$(window).scroll(function(){

    $(".navbar").removeClass('bg-red bg-gold bg-pink bg-green bg-gray bg-blue bg-purple');

    // Check if
    if($("#section-book").length){
        console.log("on olemas");
        let section_book = isAnyPartOfElementInViewport(document.getElementById("section-book"));
        if(section_book){
            $(".navbar").addClass('bg-gold');
        }
    }
    if($("#section-welcome").length){
        let section_welcome = isAnyPartOfElementInViewport(document.getElementById("section-welcome"));
        if(section_welcome){
            $(".navbar").addClass('bg-red');
        }
    }
    if($("#section-services").length){
        let section_services = isAnyPartOfElementInViewport(document.getElementById("section-services"));
        if(section_services){
            $(".navbar").addClass('bg-pink');
        }
    }
    if($("#section-trainings").length){
        let section_trainings = isAnyPartOfElementInViewport(document.getElementById("section-trainings"));
        if(section_trainings){
            $(".navbar").addClass('bg-gold');
        }
    }
    if($("#section-trainings-sub").length){
        let section_trainings_sub = isAnyPartOfElementInViewport(document.getElementById("section-trainings-sub"));
        if(section_trainings_sub){
            $(".navbar").addClass('bg-gold');
        }
    }
    if($("#section-about").length){
        let section_about = isAnyPartOfElementInViewport(document.getElementById("section-about"));
        if(section_about){
            $(".navbar").addClass('bg-green');
        }
    }
    if($("#section-portfolio").length){
        let section_portfolio = isAnyPartOfElementInViewport(document.getElementById("section-portfolio"));
        if(section_portfolio){
            $(".navbar").addClass('bg-gray');
        }
    }
    if($("#section-news").length){
        let section_news = isAnyPartOfElementInViewport(document.getElementById("section-news"));
        if(section_news){
            $(".navbar").addClass('bg-blue');
        }
    }
    if($("#section-contact").length){
        let section_contact = isAnyPartOfElementInViewport(document.getElementById("section-contact"));
        if(section_contact){
            $(".navbar").addClass('bg-purple');
        }
    }


    var scrollTop = $(this).scrollTop();
    if (scrollTop <= 0) {
        $(".navbar").addClass('bg-red');
    }

});

function isAnyPartOfElementInViewport(el) {

    const rect = el.getBoundingClientRect();
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
    const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

    const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
    const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

    return (vertInView && horInView);
}*/